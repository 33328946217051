import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	// These properties are public because they are used in the view
	public loginForm: FormGroup;
	public submitted = false;
	public hide: any;

	get f() {
		return this.loginForm.controls;
	}

	constructor(
		public dialog: MatDialog,
		public router: Router,
		private authService: AuthService,
		private formBuilder: FormBuilder
	) {}

	/****************************************************************
	 * @author naresh
	 * @description If the user is logged in then navigate to meeting
	 * page component. Else initialized the form and applied validation.
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=76
	 ****************************************************************/
	ngOnInit() {
		this.authService.getAuth().subscribe(auth => {
			if (auth) {
				this.router.navigate(['/']);
			} else {
				this.loginForm = this.formBuilder.group({
					email: ['', Validators.required],
					password: ['', Validators.required],
				});
			}
		});
	}

	/*************************************************************
	 * @author naresh
	 * @description  In this method, user login with google
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=76
	 *************************************************************/
	loginWithGoogle() {
		this.authService
			.loginWithGoogle()
			.then(() => {
				this.router.navigate(['/']);
			})
			.catch(error => {
				console.log(error);
			});
	}

	/*************************************************************
	 * @author naresh
	 * @description  In this method, user login with email and
	 * Password by "Auth Service"
	 * @condition IF - loginForm.invalid == true
	 * if above condition is true then we will not login.
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=76
	 *************************************************************/
	loginWithEmail() {
		this.submitted = true;

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		this.authService.loginWithEmailAndPassword(this.f.email.value, this.f.password.value).then(result => {
			console.log(result);
		});
	}
}
