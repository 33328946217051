import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MotionsComponent } from '../components/motions/motions.component';
import { MeetingComponent } from '../components/meeting/meeting.component';
import { AddEditMotionComponent } from '../components/motions/add-edit-motion/add-edit-motion.component';
import { LoginComponent } from '../components/login/login.component';
import { AuthService } from '../services/auth.service';

const routes: Routes = [
	{
		path: '',
		component: AddEditMotionComponent,
		canActivate: [AuthService],
	},
	{
		path: 'motions',
		component: AddEditMotionComponent,
		canActivate: [AuthService],
	},
	{
		path: 'motions/:id',
		component: MotionsComponent,
		canActivate: [AuthService],
	},
	{
		path: 'meeting',
		component: MeetingComponent,
		canActivate: [AuthService],
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: '**',
		redirectTo: '/',
		canActivate: [AuthService],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
