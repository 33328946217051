import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CoreService } from '../../services/core.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
	public isLoggedIn = false;
	public loggedInUserEmail: string;

	constructor(public authService: AuthService, public router: Router, public coreService: CoreService) {}

	ngOnInit(): void {
		this.authService.getAuth().subscribe(auth => {
			if (auth.email) this.loggedInUserEmail = auth.email;

			if (auth) {
				this.isLoggedIn = true;
			} else {
				this.isLoggedIn = false;
			}
		});
	}

	/***********************************************************************************************************************
	 * @name onMenuClick
	 * @memberof SidenavComponent
	 * @author Greg Harner
	 * @description handles the menu item click on the side menu on small devices
	 * @return {void}
	 * @link
	 ***********************************************************************************************************************/
	public onMenuClick(route: string): void {
		this.router.navigate([route]);
	}

	/***********************************************************************************************************************
	 * @name onLogoutClick
	 * @memberof SidenavComponent
	 * @author Greg Harner
	 * @description logs off an authenticated user
	 * @return {void}
	 * @link
	 ***********************************************************************************************************************/
	public onLogoutClick(): void {
		this.authService.logout();
		this.router.navigate(['/login']);
	}
}
