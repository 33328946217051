import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Attendee, Motion } from '../../../models/motion.model';

@Component({
	selector: 'seconded-dialog-component',
	templateUrl: 'seconded.dialog.component.html',
})
export class SecondedDialog implements OnInit {
	// These properties are private because they are only used in the component
	private motion: Motion;

	// These properties are public because they are used in the view
	public selectedName: any;
	public attendees: Attendee[] = [];

	constructor(public dialogRef: MatDialogRef<SecondedDialog>, @Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit() {
		this.motion = this.data.motion;
		this.attendees = this.data.attendees;
	}

	selectName(name: string) {
		this.selectedName = name;
	}

	save() {
		if (this.motion.id && this.selectedName) {
			this.motion.current.secondedBy = this.selectedName;
			this.motion.current.status = 'seconded';
			this.dialogRef.close({ motion: this.motion });
		}
	}

	onNoClick(): void {
		this.motion.current.status = 'failed';
		this.dialogRef.close({ motion: this.motion });
	}
}
