import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Attendee, Motion } from '../../models/motion.model';
import { CoreService } from '../../services/core.service';
import { CallTheQuestion } from './call-the-question/call-the-question.component';
import { DiscussionTimerDialog } from './discussion-timer/discussion-timer.dialog.component';
import { SecondedDialog } from './seconded/seconded.dialog.component';

@Component({
	selector: 'motions',
	templateUrl: './motions.component.html',
	styleUrls: ['./motions.component.css'],
})
export class MotionsComponent implements OnInit, OnDestroy {
	// These properties are private because they are only used in the component
	private attendees: Attendee[] = [];
	private dialogMotionsSubscription: Subscription;
	private dialogQuestionSubscription: Subscription;
	private dialogRef: any;
	private motion: Motion;
	private motionsSubscription: Subscription;
	private tableMotionSubscription: Subscription;
	private updateMotionSubscription: Subscription;

	// These properties are public because they are used in the view
	public amendmentBody: string = '';
	public id: any;
	public body: string = '';
	public displayedColumns: string[] = ['movedBy', 'title', 'secondedBy', 'status', 'select'];
	public meetingId: string;
	public motions: Motion[] = [];
	public proposedAmendment: boolean = false;
	public secondedBy: string;
	public secondedBtn: boolean = false;

	constructor(
		private coreService: CoreService,
		private messageService: MessageService,
		private route: ActivatedRoute,
		public dialog: MatDialog
	) {
		this.meetingId = this.route.params['value'].id;
	}

	/***********************************************************************************************************************
	 * @name ngOnInit
	 * @memberof MotionsComponent
	 * @author Greg Harner
	 * @description Get motions from 'mas-motions' collection in firestore db
	 * @function this.getMotions();
	 * @function this.getMeetings();
	 * @return {void}
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ***********************************************************************************************************************/
	ngOnInit() {
		this.getMotions();
		this.getWhoIsPresent();
	}

	/***********************************************************************************************************************
	 * @name getMotions
	 * @memberof MotionsComponent
	 * @author Greg Harner
	 * @description Get all motions from firestore db collection "mas-motions".
	 * @method getCollectionAsObservable()
	 * @return {void}
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ***********************************************************************************************************************/
	getMotions(): void {
		// hides the seconded by button by default

		this.motionsSubscription = this.coreService.getMotionsAsObservable('mas-motions', 'current.dateCreated').subscribe(
			motions => {
				this.motions = motions;

				//There should be only one motion with a status of "seconded". This motion is the current motion.
				const activeMotion = this.motions.filter(x => x.current.status == 'seconded');

				if (activeMotion?.length > 0) {
					this.motion = this.motions.filter(x => x.current.status == 'seconded')[0];
					if (!!this.motion) {
						this.id = this.motion.id;
						this.secondedBy = this.motion.current.secondedBy;
						this.body = this.motion.current.body;
						if (this.motion.current.isProposedAmendment) {
							this.proposedAmendment = true;
							this.amendmentBody = this.motion.current.body;
						}
					}
				}
			},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MotionsComponent function getMotions!',
					detail: error,
					sticky: true,
				});
			}
		);
		console.log(this.motion);
	}

	/***********************************************************************************************************************
	 * @name getWhoIsPresent
	 * @memberof  MotionsComponent
	 * @author Greg Harner
	 * @description gets a list of members present for use with the Seconded By Dialog
	 * @method his.coreService.getDocumentAsPromise('mas-meetings', this.meetingId)
	 * @return {void}
	 * @link
	 ***********************************************************************************************************************/
	getWhoIsPresent() {
		this.coreService
			.getDocumentAsPromise('mas-meetings', this.meetingId)
			.then(meeting => {
				this.attendees = meeting.attendance.filter(p => p.ispresent);
			})
			.catch(error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MotionsComponent function getMotions!',
					detail: error,
					sticky: true,
				});
			});
	}

	openSecondedByDialog(): void {
		this.dialogRef = this.dialog.open(SecondedDialog, {
			width: '250px',
			data: {
				motion: this.motion,
				attendees: this.attendees,
			},
		});

		this.dialogMotionsSubscription = this.dialogRef.afterClosed().subscribe(result => {
			this.updateMotion(result.motion);
		});
	}

	/***********************************************************************************************************************
	 * @name select
	 * @memberof MotionsComponent
	 * @author Greg Harner
	 * @descriptionThis method is used for Select Seconded by.
	 * @argument {row}
	 * @method if service @function if component
	 * @return {void}
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ***********************************************************************************************************************/
	select(row) {
		console.log(row);
		this.motion = row;
		this.secondedBtn = true;
	}

	/***********************************************************************************************************************
	 * @name showAmend
	 * @memberof MotionsComponent
	 * @author Greg Harner
	 * @description called by the "Amend" button from the view and enables the motion to be amended
	 * @function updateMotion()
	 * @return {void}
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ***********************************************************************************************************************/
	showAmend() {
		let amendmentsArray = [];

		// prepare current motion to be an amendment
		let target = Object.assign({}, this.motion.current);
		delete target.isProposedAmendment;
		delete target.isActiveMotion;
		target.status = 'amendment';

		if (this.motion.amendments) {
			// if there are existing motions add them to the array
			amendmentsArray = this.motion.amendments;
			// then add current to the array
			amendmentsArray.push(target);
		} else {
			// just add the current motion to the array
			amendmentsArray.push(target);
		}

		// the current motion is now a proposed amendment
		this.motion.current.isProposedAmendment = true;
		this.motion.current.secondedBy = '';

		// update the motion amendments
		this.motion.amendments = amendmentsArray;

		this.updateMotion(this.motion);
		this.proposedAmendment = true;
		this.amendmentBody = this.motion.current.body;
	}

	/*******************************************************************************************
	 * @author Greg Harner
	 * @description This method is used for hide Call The Question.
	 *
	 * @property this.proposedAmendment @type {boolean} is strored false.
	 * @property this.amendmentBody @type {object} is stored null value .
	 *
	 * @see MotionsComponent @function callQuestion() and @function select()
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ******************************************************************************************/
	hideAmend() {
		this.proposedAmendment = false;
		this.amendmentBody = '';
	}

	/*******************************************************************************************
	 * @author Greg Harner
	 * @description update motion status is "Tabled" in firestore db 'mas-motion'.
	 *
	 * @constant motion @type {object} is stored json data.
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ******************************************************************************************/
	addTabled() {
		this.motion.current.status = 'Tabled';

		console.log(this.motion);
		this.updateMotion(this.motion);
	}

	/***********************************************************************************************************************
	 * Calls the updateMotion method of coreService to `update` the document with the given `id` in the `mas-motion`
	 * collection.
	 * @param motion a custom interface in the model `motion.model.ts
	 * @return {void}
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ***********************************************************************************************************************/
	updateMotion(motion: Motion) {
		console.log(motion);
		this.coreService.update('mas-motions', motion.id, motion);
	}

	getStatus() {
		const data = this.motions.filter(x => x.current.status == 'seconded');
		if (data?.length > 0) {
			return true;
		}
		return false;
	}

	/*******************************************************************************************
	 * @author Greg Harner
	 * @description This method is used for open MotionApprovedRejectedDialog modal.
	 *
	 * @constant dialogref @type {object} is used to open MotionApprovedRejectedDialog modal.
	 *
	 * @function dialogRef.afterClosed() is used to call @function hideAmend()
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ******************************************************************************************/
	callQuestion(mode) {
		const dialogRef = this.dialog.open(CallTheQuestion, { width: '250px', data: { mode: mode } });

		this.dialogQuestionSubscription = dialogRef.afterClosed().subscribe(result => {
			console.log('result', result);
			console.log(this.motion);
			if (result.status === 'approved') {
				this.motion.current.status = 'approved';
			}

			return;

			this.hideAmend();
			// this.selectSecondBy = false;
		});
	}

	/*******************************************************************************************
	 * @author Greg Harner
	 * @description In this method, Open DiscussionTimerDialog modal.
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=71
	 ******************************************************************************************/
	openSetDiscussionTimerDialog() {
		const dialogRef = this.dialog.open(DiscussionTimerDialog, {
			width: '280px',
		});
	}

	ngOnDestroy() {
		if (this.motionsSubscription) this.motionsSubscription.unsubscribe();
		if (this.dialogMotionsSubscription) this.dialogMotionsSubscription.unsubscribe();
		if (this.dialogQuestionSubscription) this.dialogQuestionSubscription.unsubscribe();
		if (this.tableMotionSubscription) this.tableMotionSubscription.unsubscribe();
		if (this.updateMotionSubscription) this.updateMotionSubscription.unsubscribe();
	}
}
