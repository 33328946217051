import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { gapiSettings } from '../../environments/gapisettings';

declare var gapi: any;
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	user$: Observable<firebase.User>;

	constructor(public afAuth: AngularFireAuth, public router: Router) {
		this.initClient();
		this.user$ = afAuth.authState;
	}

	initClient() {
		gapi.load('client', () => {
			gapi.client.init(gapiSettings.Cfg);
			gapi.client.load('people', 'v1');
		});
	}

	async loginWithGoogle() {
		const googleAuth = gapi.auth2.getAuthInstance();
		const googleUser = await googleAuth.signIn();
		const token = googleUser.getAuthResponse().id_token;
		const credential = auth.GoogleAuthProvider.credential(token);

		return await this.afAuth.signInWithCredential(credential).catch(error => {
			console.log(error);
		});
	}

	async loginWithEmailAndPassword(email: string, password: string) {
		return await this.afAuth.signInWithEmailAndPassword(email, password).catch(error => {
			console.log(error);
		});
	}

	async logout() {
		await this.afAuth.signOut().catch(error => {
			console.log(error);
		});
	}

	public getAuth(): Observable<any> {
		return this.afAuth.authState.pipe(map(user => user));
	}

	canActivate(): Observable<boolean> {
		return this.afAuth.authState.pipe(
			map(auth => {
				if (!auth) {
					this.router.navigate(['/login']);
					return false;
				} else {
					return true;
				}
			})
		);
	}
}
