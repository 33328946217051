import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoreService } from 'src/app/services/core.service';
import { DialogData, Motion, Current, Amendments } from '../../../models/motion.model';

@Component({
	selector: 'add-edit-motion-dialog',
	templateUrl: 'add-edit-motion.dialog.component.html',
})
export class AddEditMotionDialog implements OnInit, OnDestroy {
	// These properties are private because they are only used in the component
	private deleteSubscription: Subscription;
	private motionId: string = '';
	private motionsSubscription: Subscription;
	private updateSubscription: Subscription;
	private motion: Motion | null;

	// These properties are public because they are used in the view
	public isAdd: boolean = false;
	public isEdit: boolean = false;
	public isRemove: boolean = false;
	public title: string | null = null;
	public body: string | null = null;

	constructor(
		private coreService: CoreService,
		public dialogRef: MatDialogRef<AddEditMotionDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {}

	/*************************************************************************
	 * @author naresh
	 * @description In this method, data is send by "AddEditMotionComponent"
	 *
	 * @condition If - data is not null or empty && data.list is not null or
	 * empty && data.list.motion is not null or empty
	 * if above conditions are true then we set motion == data.list.motion
	 * and "isAddEdit"    == true.
	 * @condition If - data is not null or empty && data.list is not null or
	 * empty && data.list.isAddEdit  is not null or empty
	 * if above conditions are true then we set  "isAddEdit"    == true
	 * @condition Else - if else condition are true then we set
	 * "isAddEdit" == false and motionId == data.list.motionId.
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=75
	 ************************************************************************/
	ngOnInit() {
		if (this.data?.mode === 'add') {
			this.isAdd = true;
			this.motion = { id: null, current: null };
		} else if (this.data?.mode === 'edit') {
			this.isEdit = true;
			this.title = this.data.motion.current.title;
			this.body = this.data.motion.current.body;
			this.motion = this.data.motion;
		} else if (this.data?.mode === 'remove') {
			this.isRemove = true;
			this.motionId = this.data.motion.id;
		}
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method, close the Add/Edit popup
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=75
	 *************************************************************/
	close(): void {
		this.dialogRef.close();
	}

	/************************************************************************
	 * @author naresh
	 * @description In this method, Add/Update motion and close popup
	 * save motion
	 *
	 * @condition  If  - If is motion id>0,then call the update method
	 * @condition Else - if is motion id == 0, then call the Insert method
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=75
	 ***********************************************************************/
	save() {
		let payload: Current = {
			current: {
				title: this.title,
				body: this.body,
				dateCreated: !!this.motion?.current?.dateCreated ? this.motion.current.dateCreated : new Date(),
				dateDecided: !!this.motion?.current?.dateDecided
					? this.motion.current.dateDecided
					: !!this.motion?.current?.dateCreated
					? this.motion.current.dateCreated
					: new Date(),
				movedBy: this.coreService.loggedInUser,
				secondedBy: !!this.motion?.current?.secondedBy ? this.motion.current.secondedBy : null,
				status: !!this.motion?.current?.status ? this.motion.current.status : 'new',
				isActiveMotion: !!this.motion?.current?.isActiveMotion ? this.motion.current.isActiveMotion : false,
				isProposedAmendment: !!this.motion?.current?.isProposedAmendment
					? this.motion.current.isProposedAmendment
					: false,
			},
		};

		this.motion.current = payload.current;

		if (this?.motion.id) {
			this.coreService.update('mas-motions', this.motion.id, this.motion);
		}
		this.dialogRef.close();
	}

	/******************************************************************
	 * @author naresh
	 * @description In this method, delete the document by motionId
	 *
	 * @condition First If Case - motionId is not null or empty
	 * if above conditions are true then we call the Close popup and
	 * deleteDocument methods.
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=75
	 *****************************************************************/
	remove() {
		if (!!this.motionId) {
			this.dialogRef.close();
			this.deleteSubscription = this.coreService.deleteDocument('mas-motions', this.motionId).subscribe(res => {});
		}
	}

	ngOnDestroy(): void {
		if (this.motionsSubscription) this.motionsSubscription.unsubscribe();
		if (this.updateSubscription) this.updateSubscription.unsubscribe();
		if (this.deleteSubscription) this.deleteSubscription.unsubscribe();
	}
}
