import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Account } from '../../models/accounts.model';
import { Attendance, Meeting } from '../../models/meeting.model';
import { Motion } from '../../models/motion.model';
import { CoreService } from '../../services/core.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-meeting',
	templateUrl: './meeting.component.html',
	styleUrls: ['./meeting.component.css'],
})
export class MeetingComponent implements OnInit, OnDestroy {
	// These properties are private because they are only used in the component
	private accounts: Account[] = [];
	private legacyId: string = '';
	private legacySubscription: Subscription;
	private meetingSubscription: Subscription;
	private motionsSubscription: Subscription;
	private startMeetingSubscription: Subscription;
	private accountsSubscription: Subscription;

	// These properties are public because they are used in the view
	public attendanceResult: number = 0;
	public currentMeeting: any;
	public displayedColumns: string[] = ['name', 'present', 'not-present'];
	public isActiveMotion: string = '';
	public legacyAccounts: Attendance[] = [];
	public meetingId: string = '';
	public motions: Array<Motion>;
	public rollCallComplete: boolean = false;

	constructor(private coreService: CoreService, public messageService: MessageService, private router: Router) {
		this.motions = new Array<Motion>();
	}

	/***********************************************************************************************************************
	 * Call getMeetings and getAccounts
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#diagram:workspace=dxjqrqux&proj=11&id=70&page=7ueGkPZeLNEaYLeN6n
	 ***********************************************************************************************************************/
	ngOnInit(): void {
		this.getMeetings();
		this.getAccounts();
	}

	/***********************************************************************************************************************
	 * Get active Meeting, Attandance and meetingId from Firecloud `mas-meeting` collection
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	getMeetings(): void {
		this.accountsSubscription = this.coreService.getCollectionAsObservable('mas-meetings', 'header').subscribe(
			(meetings: Meeting[]) => {
				// The active meeting is should be the only meeting without an endTime
				const meeting = meetings.find(x => !x.header.endTime);
				if (!!meeting) {
					// Get the id of the Legacy Program
					this.getLegacyId();

					this.currentMeeting = Object.assign({}, meeting);

					let attendance = Object.assign([], meeting.attendance);

					// If there is a active meeting get those in attendance
					let rollCall = attendance;
					this.attendanceResult = rollCall.length;

					// the meetingID is the same as the collecton document id
					this.meetingId = meeting.id;
				}
			},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MeetingComponent function getMeetings!',
					detail: error,
					sticky: true,
				});
			}
		);
	}

	/***********************************************************************************************************************
	 * Get accounts from Firecloud `mas-accounts` collection
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	getAccounts(): void {
		this.coreService.getCollectionAsObservable('mas-accounts', 'etag').subscribe(
			(accounts: Account[]) => {
				if (accounts?.length > 0) {
					this.accounts = accounts;
				}
			},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MeetingComponent function getAccounts!',
					detail: error,
					sticky: true,
				});
			}
		);
	}

	/***********************************************************************************************************************
	 * Set start meetings in mas-meetings by call api post method.
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	startMeeting(): void {
		const header: Meeting = { header: { startTime: new Date() } };

		this.startMeetingSubscription = this.coreService.addMeeting('mas-meetings', header).subscribe(
			(meeting: Meeting) => {
				if (meeting?.id) {
					this.meetingId = meeting.id;
					this.getLegacyId();
				}
			},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MeetingComponent function startMeeting!',
					detail: error,
					sticky: true,
				});
			}
		);
	}

	/***********************************************************************************************************************
	 * get legacy id from `mas-programs` to set property `this.legacyId` then call `this.LegacyMembers`
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	getLegacyId(): void {
		this.legacySubscription = this.coreService.getCollectionAsObservable('mas-programs', 'name').subscribe(
			res => {
				if (res?.length > 0) {
					const legacy = res.find(x => x['name'] == 'Legacy');
					if (!!legacy) {
						this.legacyId = legacy['id'];
						this.getLegacyMembers();
					}
				}
			},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MeetingComponent function getLegacyId!',
					detail: error,
					sticky: true,
				});
			}
		);
	}

	/***********************************************************************************************************************
	 * filter `this.membershipArray` to create `this.legacyAccounts`
	 * @return {void}
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	getLegacyMembers(): void {
		if (this?.accounts.length > 0) {
			this.accounts.forEach(account => {
				account?.memberships
					?.filter(x => x.contactGroupId == this.legacyId)
					.map(() => {
						this.legacyAccounts.push({
							accountId: account.id,
							isPresent: null,
							name: `${account.names.givenName} ${account.names.familyName}`,
						});
					});
			});
		}
		if (this.attendanceResult === this.legacyAccounts.length) this.rollCallComplete = true;
	}

	/***********************************************************************************************************************
	 * This function is called from the view and is used to push and remove data from attendanceResult array on the basis
	 * of row.id.
	 * @param isPresent Records the attendance of each Legacy Member
	 * @param row the values of a given row from a table of Legacy Members
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	attendance(isPresent: string, row: Attendance): void {
		const index = this.legacyAccounts.findIndex(x => x.accountId == row.accountId);
		this.legacyAccounts[index].isPresent = isPresent;
		let rollCall = this.legacyAccounts.filter(count => count.isPresent !== null);
		if (rollCall.length === this.legacyAccounts.length) this.rollCallComplete = true;
	}

	/***********************************************************************************************************************
	 * Call update attandance method in api by meetingID.
	 * @return {void}
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 ***********************************************************************************************************************/
	attendanceComplete(): void {
		try {
			const attendance = { attendance: this.legacyAccounts };
			this.coreService.update('mas-meetings', this.meetingId, attendance);
		} catch (error) {
			console.log(error);
			this.messageService.add({
				severity: 'error',
				summary: 'Something went wrong in the MeetingComponent function attendanceComplete!',
				detail: error,
				sticky: true,
			});
		} finally {
			this.router.navigate(['/motions', this.meetingId]);
		}
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method, call update meeting method in
	 * api by meetingID.
	 *
	 * @argument meetingID @type {object} son that will be used by
	 * get meeting detail in api method
	 * @argument currentMeeting @type {object} json that will be used
	 * by save meeting in api method
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=70
	 *************************************************************/
	endMeeting(): void {
		this.currentMeeting.header.endTime = new Date();

		this.coreService.update('mas-meetings', this.meetingId, this.currentMeeting);
	}

	ngOnDestroy(): void {
		if (this.accountsSubscription) this.accountsSubscription.unsubscribe();
		if (this.meetingSubscription) this.meetingSubscription.unsubscribe();
		if (this.startMeetingSubscription) this.startMeetingSubscription.unsubscribe();
		if (this.legacySubscription) this.legacySubscription.unsubscribe();
		if (this.motionsSubscription) this.motionsSubscription.unsubscribe();
	}

	maint(): void {
		this.coreService.getAllAccounts().subscribe(accounts => {
			accounts.forEach(account => {
				if (account?.memberships) {
					if (!account.memberships.length) {
						let membershipArray = [];
						for (const key in account.memberships) {
							if (Object.prototype.hasOwnProperty.call(account.memberships, key)) {
								const element = account.memberships[key];
								membershipArray.push(element);
							}
						}

						// finally, update account in Firecloud
						this.coreService.update('mas-accounts', account.id, { memberships: membershipArray });
					}
				}
			});
		});
	}
}
