import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, Motion } from '../../../models/motion.model';
@Component({
	selector: 'discussion-timer-dialog',
	templateUrl: 'discussion-timer.dialog.component.html',
	styleUrls: ['./discussion-timer.dialog.component.css'],
})

// tslint:disable-next-line:component-class-suffix
export class DiscussionTimerDialog implements OnInit {
	selectedName: any;
	discussionTimerForm: FormGroup;
	motion: Motion;
	timer: number;
	timeLeft: number = 0;
	timerType: string;
	submitted = false;
	countTimer: any;
	timerTypes: any = [{ value: 'Second' }, { value: 'Minute' }, { value: 'Hour' }];
	secoundedBy: any[] = [];
	interval;
	constructor(
		public dialogRef: MatDialogRef<DiscussionTimerDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private formBuilder: FormBuilder
	) {
		//this.motion = new Motion();
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method,close the discussion timer popup
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=44
	 *************************************************************/
	stopDiscussionTimerDialog(): void {
		this.dialogRef.close();
	}

	/*************************************************************
	 * @author naresh
	 * @description Initialized the form and applied validation
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=44
	 *************************************************************/
	ngOnInit() {
		this.discussionTimerForm = this.formBuilder.group({
			timerType: ['', Validators.required],
			timer: ['', Validators.required],
		});
	}

	/*************************************************************
	 * @author naresh
	 * @description It is used to get the form controls
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=44
	 *************************************************************/
	get f() {
		return this.discussionTimerForm.controls;
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method, start the discussion timer
	 *
	 * @condition First If Case - discussionTimerForm is invalid
	 * if above condition are true then we set dirty true in
	 * timer and timerType discussionTimerForm field.
	 * @condition Second If Case - f.timerType.value == "Minute"
	 * if above condition is true then we set countTimer =
	 * Math.floor(this.f.timer.value * 60).
	 * @condition Third If Case - f.timerType.value == "Hour"
	 * if above condition is true then we set countTimer =
	 * Math.floor(this.f.timer.value * 3600).
	 * @condition Fourth If Case - f.timerType.value == "Second"
	 * if above condition is true then we set countTimer =
	 * Math.floor(this.f.timer.value).
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=44
	 *************************************************************/
	startTimer() {
		this.submitted = true;
		if (this.discussionTimerForm.invalid) {
			this.discussionTimerForm.controls.timer.markAsDirty();
			this.discussionTimerForm.controls.timerType.markAsDirty();
			return;
		}
		if (this.f.timerType.value == 'Minute') {
			this.countTimer = Math.floor(this.f.timer.value * 60);
		}
		if (this.f.timerType.value == 'Hour') {
			this.countTimer = Math.floor(this.f.timer.value * 3600);
		}
		if (this.f.timerType.value == 'Second') {
			this.countTimer = Math.floor(this.f.timer.value);
		}
		this.interval = setInterval(() => {
			if (this.timeLeft < this.countTimer) {
				this.timeLeft++;
			} else {
				this.dialogRef.close();
			}
		}, 1000);
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method, paused the discussion timers
	 *
	 * @link https://diagrams.visual-paradigm.com/#workspace=dxjqrqux&proj=11&id=44
	 *************************************************************/
	pauseTimer() {
		clearInterval(this.interval);
	}
}
