import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../models/motion.model';

@Component({
	selector: 'call-the-question',
	templateUrl: 'call-the-question.component.html',
})
export class CallTheQuestion {
	constructor(public dialogRef: MatDialogRef<CallTheQuestion>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

	onClick(status: string): void {
		this.dialogRef.close({ status: status });
	}
}
