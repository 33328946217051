import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { firebase } from '../../environments/environment';
import { Meeting } from '../models/meeting.model';
import { Motion } from '../models/motion.model';
@Injectable({
	providedIn: 'root',
})
export class CoreService {
	public loggedInUser: string;

	constructor(public afs: AngularFirestore, private http: HttpClient) {}

	/*************************************************************
	 * @name getDocumentAsObservable
	 * @author Greg Harner
	 * @description get a document from the Firestore db as an
	 * observable
	 * @argument col - collection name
	 * @argument doc - document id
	 *************************************************************/
	getDocumentAsObservable(col: string, doc: string) {
		return this.afs.collection(col).doc(doc).valueChanges();
	}

	/*************************************************************
	 * @name getDocumentAsPromise
	 * @author Greg Harner
	 * @description get a document from the Firestore db as a
	 * promise
	 * @argument col - collection name
	 * @argument doc - document id
	 *************************************************************/
	getDocumentAsPromise(col: string, doc: string) {
		return this.afs
			.collection(col)
			.doc(doc)
			.get()
			.toPromise()
			.then(res => {
				return res.data();
			});
	}

	/*************************************************************
	 * @name getCollectionAsObservable
	 * @author Greg Harner
	 * @description get a collection from the Firestore db as an
	 * observable
	 * @argument col - collection name
	 * @argument sort - collection Order By
	 *************************************************************/
	getCollectionAsObservable(col: string, sort: string) {
		return this.afs
			.collection(col, ref => ref.orderBy(sort))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(res => {
						const data = res.payload.doc.data();
						data['id'] = res.payload.doc.id;

						return data;
					});
				})
			);
	}

	/*************************************************************
	 * @name getCollectionAsObservable
	 * @author Greg Harner
	 * @description get a collection from the Firestore db as an
	 * observable
	 * @argument col - collection name
	 * @argument sort - collection Order By
	 *************************************************************/
	getMotionsAsObservable(col: string, sort: string) {
		return this.afs
			.collection(col, ref => ref.orderBy(sort))
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(res => {
						const data = res.payload.doc.data() as Motion;
						data.id = res.payload.doc.id;
						return data;
					});
				})
			);
	}

	addMeeting(collection: string, meeting: Meeting): Observable<Meeting> {
		return new Observable(observer => {
			this.afs
				.collection(collection)
				.add(meeting)
				.then(doc => {
					observer.next({
						id: doc.id,
					});
				});
		});
	}

	update(col: string, doc: string, data: any): void {
		this.afs.firestore
			.collection(col)
			.doc(doc)
			.update(data)
			.catch(error => console.log(error));
	}

	deleteDocument(col: string, doc: string) {
		return new Observable(observer => {
			this.afs.firestore
				.collection(col)
				.doc(doc)
				.delete()
				.then(result => {
					observer.next({
						result,
					});
				})
				.catch(error => console.log(error.message));
		});
	}

	public CreateUser(model: any) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'secret-key',
			}),
		};
		this.http.post<any>(firebase.apiEndpoint + '/signup', model, httpOptions).subscribe(
			res => {
				console.log(res);
				if (res?.message) alert(res.message);
			},
			err => {
				console.log('Error occured');
				alert(err.message);
			}
		);
	}

	getAllAccounts(): Observable<any[]> {
		return this.afs
			.collection('mas-accounts')
			.snapshotChanges()
			.pipe(
				map(changes => {
					return changes.map(a => {
						const data = a.payload.doc.data() as Account;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}
}
