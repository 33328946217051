import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { LayoutComponent } from '../components/layout/layout.component';
import { LoginComponent } from '../components/login/login.component';
import { MeetingComponent } from '../components/meeting/meeting.component';
import { AddEditMotionComponent } from '../components/motions/add-edit-motion/add-edit-motion.component';
import { AddEditMotionDialog } from '../components/motions/add-edit-motion/add-edit-motion.dialog.component';
import { CallTheQuestion } from '../components/motions/call-the-question/call-the-question.component';
import { DiscussionTimerDialog } from '../components/motions/discussion-timer/discussion-timer.dialog.component';
import { MotionsComponent } from '../components/motions/motions.component';
import { SecondedDialog } from '../components/motions/seconded/seconded.dialog.component';
import { MaterialModule } from './material.module';
import { NGPrimeModule } from './ngprime.module';
import { AppRoutingModule } from './routing.module';

@NgModule({
	declarations: [
		AddEditMotionComponent,
		AddEditMotionDialog,
		AppComponent,
		CallTheQuestion,
		DiscussionTimerDialog,
		LayoutComponent,
		LoginComponent,
		MeetingComponent,
		MotionsComponent,
		SecondedDialog,
	],
	imports: [
		AngularFireAuthModule,
		AngularFireModule.initializeApp(firebase.Cfg, 'angularfs'), // change import also
		AngularFirestoreModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		MaterialModule,
		NGPrimeModule,
		ReactiveFormsModule,
	],
	providers: [{ provide: SETTINGS, useValue: {} }],
	bootstrap: [AppComponent],
	entryComponents: [AddEditMotionDialog],
})
export class AppModule {}
