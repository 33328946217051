import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CoreService } from '../../../services/core.service';
import { AddEditMotionDialog } from './add-edit-motion.dialog.component';

@Component({
	selector: 'add-edit-motion',
	templateUrl: './add-edit-motion.component.html',
	styleUrls: ['./add-edit-motion.component.css'],
})
export class AddEditMotionComponent implements OnInit, OnDestroy {
	private motionsSubscription: Subscription;
	private dialogSubscription: Subscription;

	motions: any[] = [];
	newMotions: any[] = [];
	tabledMotions: any[] = [];
	userId: string;
	newTabledMotionColumns: string[] = ['movedBy', 'title', 'status', 'select'];
	tabledMotionColumns: string[] = ['movedBy', 'title', 'status'];
	isEdit = false;

	constructor(private coreService: CoreService, private messageService: MessageService, public dialog: MatDialog) {}

	/***************************************************************************************
	 * @author naresh
	 * @description 1.API - Get Motions
	 *              2.Filter motions having status new and store in newMotions variable
	 *              3.Filter motions having status tabled and store in tabledMotions variable
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=74
	 *****************************************************************************************/
	ngOnInit() {
		this.coreService.getMotionsAsObservable('mas-motions', 'current.dateCreated').subscribe(motions => {
			this.motions = motions;
			this.newMotions = this.motions.filter(x => x.current.status.toLowerCase() != 'tabled');
			this.tabledMotions = this.motions.filter(x => x.current.status.toLowerCase() == 'tabled');
		});
	}

	/*************************************************************
	 * @author naresh
	 * @description In this method, set row to motion property and
	 * 2nd property is isAddEdit which is true. Row is an optional
	 * parameter which is null in case of add and having motion in
	 * case of edit.
	 *
	 * @argument motion @type {object} json that will be used by the
	 * set data
	 *
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=74
	 *************************************************************/
	motionAddEdit(mode: string, row = null) {
		this.motionModal(mode, row);
	}

	/***********************************************************************************************************************
	 * In this method is responsible to open modal and perform given operations such as add, edit and remove motion.
	 * @param motion json that will be used by the set data
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=74
	 ***********************************************************************************************************************/
	motionRemove(mode: string, id) {
		this.motionModal(mode, id);
	}

	/***********************************************************************************************************************
	 * In this method, Open the addEditRemoveModal modal.
	 * @param mode description
	 * @param motion description
	 * @return void
	 * @link https://online.visual-paradigm.com/w/dxjqrqux/diagrams/#workspace=dxjqrqux&proj=11&id=74
	 ***********************************************************************************************************************/
	motionModal(mode: string, motion: any): void {
		const data = { mode: mode, motion: motion };
		console.log(data);
		const dialogRef = this.dialog.open(AddEditMotionDialog, { data });

		this.dialogSubscription = dialogRef.afterClosed().subscribe(
			() => {},
			error => {
				console.log(error);
				this.messageService.add({
					severity: 'error',
					summary: 'Something went wrong in the MotionsComponent function getMotions!',
					detail: error,
					sticky: true,
				});
			}
		);
	}

	ngOnDestroy() {
		if (this.motionsSubscription) this.motionsSubscription.unsubscribe();
		if (this.dialogSubscription) this.dialogSubscription.unsubscribe();
	}
}
